body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Add custom google fonts */
@font-face {
  font-family: Allura;
  src: url(/fonts/Allura/Allura-Regular.ttf);
}
@font-face {
  font-family: AmaticSC;
  src: url(/fonts/AmaticSC/AmaticSC-Regular.ttf);
}
@font-face {
  font-family: Caveat;
  src: url(/fonts/Caveat/Caveat-Regular.ttf);
}
@font-face {
  font-family: Charmonman;
  src: url(/fonts/Charmonman/Charmonman-Regular.ttf);
}
@font-face {
  font-family: Comfortaa;
  src: url(/fonts/Comfortaa/Comfortaa-Regular.ttf);
}
@font-face {
  font-family: CutiveMono;
  src: url(/fonts/CutiveMono/CutiveMono-Regular.ttf);
}
@font-face {
  font-family: GiveYouGlory;
  src: url(/fonts/GiveYouGlory/GiveYouGlory.ttf);
}
@font-face {
  font-family: Gruppo;
  src: url(/fonts/Gruppo/Gruppo-Regular.ttf);
}
@font-face {
  font-family: JuliusSansOne;
  src: url(/fonts/JuliusSansOne/JuliusSansOne-Regular.ttf);
}
@font-face {
  font-family: MrDafoe;
  src: url(/fonts/MrDafoe/MrDafoe-Regular.ttf);
}
@font-face {
  font-family: Nunito;
  src: url(/fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: PoiretOne;
  src: url(/fonts/PoiretOne/PoiretOne-Regular.ttf);
}
@font-face {
  font-family: ReenieBeanie;
  src: url(/fonts/ReenieBeanie/ReenieBeanie.ttf);
}
@font-face {
  font-family: RockSalt;
  src: url(/fonts/RockSalt/RockSalt-Regular.ttf);
}
@font-face {
  font-family: Sacramento;
  src: url(/fonts/Sacramento/Sacramento-Regular.ttf);
}
@font-face {
  font-family: Sanchez;
  src: url(/fonts/Sanchez/Sanchez-Regular.ttf);
}
@font-face {
  font-family: Satisfy;
  src: url(/fonts/Satisfy/Satisfy-Regular.ttf);
}
@font-face {
  font-family: ShadowsIntoLight;
  src: url(/fonts/ShadowsIntoLight/ShadowsIntoLight.ttf);
}